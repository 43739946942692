import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import IncomeIcon from '../../assets/svg/income.svg';
import { Form, IconBox } from '../Authentication/common/AuthStyle';
import Chip from '@mui/material/Chip';
const FormStack = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  width: '100%',
  padding: '10px',
  border: ' 1px solid rgba(0, 24, 35, 0.1)',
  borderRadius: '15px',

  ':hover': {
    backgroundColor: '#ffe6d5',
    border: `1px solid ${theme.palette.orange.main}`,

    '& .icn': {
      backgroundColor: '#fff',
    },
  },
}));

const Products = () => {
  const [allProducts, setAllProducts] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [productEntitlement, setProductEntitlement] = React.useState(null);
  const [resultList, setResultList] = useState();

  const authToken = JSON.parse(localStorage.getItem('authUser'));

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_EDOC_API_URL}/api/v1/businessPartner/getBusinessPartnerProductEntitlement`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken?.data?.accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setProductEntitlement(result.data);
      });
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_EDOC_API_URL}/api/v1/businessPartner/listAllProducts`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken?.data?.accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setResultList(result);
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to fetch all products');
      });
  }, [productEntitlement]);
  useEffect(() => {
    if (resultList) {
      checkProductRequest();
    }
  }, [resultList]);

  const checkProductRequest = () => {
    const updatedProducts = resultList.data.map((product) => {
      const entitlement = productEntitlement?.find(
        (ent) => ent.productCode === product.code
      );
      return {
        ...product,
        status: entitlement?.status || product.status,
      };
    });
    setAllProducts(updatedProducts);
  };

  const handleClickNext = () => {
    const selectedProduct = allProducts.find((p) => p.code === selectedValue);
    const data = {
      product: {
        id: selectedProduct?.id,
        price: selectedProduct?.price,
      },
      permissions: ['BasicTransactions'],
    };
    fetch(
      `${process.env.REACT_APP_EDOC_API_URL}/api/v1/businessPartner/requestProduct`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken?.data?.accessToken}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        checkProductRequest();
        toast.success(result.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to fetch all products');
      });
  };

  return (
    <>
      <Box>
        <Typography variant="Body_medium_20">Select a product</Typography>
      </Box>

      <Form>
        <RadioGroup
          name="registrationType"
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
        >
          <Grid container spacing={2}>
            {allProducts.length ? (
              allProducts.map((product) => (
                <Grid item xs={12} key={product.code}>
                  <FormStack direction={'row'} alignItems={'center'} gap={2}>
                    <IconBox className="icn">
                      <Box component={'img'} src={IncomeIcon} />
                    </IconBox>

                    <FormControlLabel
                      value={product.code}
                      control={<Radio />}
                      label={
                        <Box display="flex" alignItems="center">
                          <Typography>{product.name}</Typography>
                          {product.status && (
                            <Chip
                              label={product.status}
                              size="small"
                              sx={{
                                color:
                                  product.status === 'APPROVED'
                                    ? '#1CB10F'
                                    : product.status === 'PENDING'
                                    ? '#ACAC2A'
                                    : product.status === 'REJECTED'
                                    ? '#D32F2F'
                                    : 'inherit',
                                backgroundColor:
                                  product.status === 'APPROVED'
                                    ? '#E8F7E7'
                                    : product.status === 'PENDING'
                                    ? '#FFFFE5'
                                    : product.status === 'REJECTED'
                                    ? '#FDECEA'
                                    : 'inherit',
                                fontSize: '12px',
                                marginLeft: 1,
                              }}
                            />
                          )}
                        </Box>
                      }
                      labelPlacement="start"
                      disabled={
                        product.status === 'PENDING' ||
                        product.status === 'APPROVED'
                      }
                      sx={{
                        flex: 1,
                        margin: 0,
                        justifyContent: 'space-between',
                      }}
                    />
                  </FormStack>
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
        </RadioGroup>

        <Stack direction={'row'} alignItems={'center'} my={3} gap={1}>
          <Button variant="contained" onClick={handleClickNext}>
            Select
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: 'common.white',
              color: 'common.black',
              border: '1px solid rgba(33, 33, 32, 0.2)',
              ':hover': {
                backgroundColor: '#f4f4f4',
                color: 'common.black',
                border: '1px solid #f4f4f4',
              },
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Form>
    </>
  );
};

export default Products;