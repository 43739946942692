import { colors, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexSingleLineChart = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const options = {
    chart: {
      type: 'line',
      height: isMobile ? 250 : isTablet ? 300 : 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Removes the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
      colors: ['#FC5719'], // Sets line color
      width: 2,
    },
    grid: {
      row: {
        // colors: ['#f3f3f3', 'transparent'], // Alternating grid colors
        // opacity: 0.5
      },
    },
    xaxis: {
      categories: data.categories,
      labels: {
        style: {
          colors: '#FF0000', // Change this to your desired color
        },
      },
      axisBorder: {
        show: true,
        color: '#004466',
        height: '2.5px',
        // Change this to your desired color
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#0000FF', // Change this to your desired color
        },
        formatter: (value) => value.toLocaleString(), // Format numbers with commas
      },
      axisBorder: {
        show: true,
        color: '#004466',
        width: '2.5px', // Change this to your desired color
      },
    },
  };

  const series = [
    {
      name: "Data",
      data: data.values,
      color: "#FC5719", // Example: red line color
      strokeWidth: 2,    // Example: line width
    }
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={'100%'}
    />
  );
};

export default ApexSingleLineChart;