import React, { useEffect, useState } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import mapData from './nigeria_geojson.json'; // Ensure this file contains Nigeria's GeoJSON
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useTheme, useMediaQuery } from '@mui/material';

const MyMap = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [updatedMapData, setUpdatedMapData] = useState();

  useEffect(() => {
    const spendingByLocation =
      data?.additional_analysis?.location_analysis?.reduce((acc, txn) => {
        acc[txn.location] = (acc[txn.location] || 0) + txn.amount;
        return acc;
      }, {});

    // Modify mapData to include fund info dynamically
    const mapDataList = {
      ...mapData,
      features: mapData.features.map((feature) => {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            fund:
              (spendingByLocation &&
                spendingByLocation[feature?.properties?.state]) ||
              0,
          },
        };
      }),
    };

    setUpdatedMapData(mapDataList);
  }, [data]);

  // Function to determine color dynamically
  const getCountryStyle = (feature) => {
    const fund = feature.properties.fund || 0;
    return {
      fillColor: fund > 0 ? '#FC5719' : '#FFFFFF', // Orange if data exists, White otherwise
      fillOpacity: 1,
      color: 'black',
      weight: 2,
    };
  };

  const onEachCountry = (feature, layer) => {
    const stateName = feature.properties.state;
    const fund = feature.properties.fund || 'No Data';

    layer.on('mouseover', function (e) {
      layer
        .bindPopup(`${stateName} <br/> Total Fund Spent: ${fund}`)
        .openPopup();
    });

    layer.options.fillOpacity = fund > 0 ? 0.8 : 0.3; // Adjust opacity for better visibility
  };;

  const addTitleControl = (map) => {
    const title = L.control({ position: 'topleft' });

    title.onAdd = function () {
      const div = L.DomUtil.create('div', 'map-title');
      div.style.marginTop = '25px';
      div.style.color = 'black';
      return div;
    };

    title.addTo(map);
  };

  const getZoomLevel = () => {
    if (isMobile) return 5;
    if (isTablet) return 6;
    if (isDesktop) return 7;
    return 6;
  };

  return (
    <div>
      <MapContainer
        style={{
          height: '65vh',
          marginTop: '80px',
          backgroundColor: '#fff',
          maxWidth: '100%',
          marginLeft: isMobile ? '0' : isTablet ? '50px' : '100px',
          borderRadius: '10px',
        }}
        zoom={getZoomLevel()}
        center={[10, 8]}
        zoomControl={false}
        attributionControl={false}
        whenCreated={(mapInstance) => {
          addTitleControl(mapInstance);
        }}
      >
        {updatedMapData && (
          <GeoJSON
            style={getCountryStyle} // Dynamic color styling
            data={updatedMapData?.features || []}
            onEachFeature={onEachCountry}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default MyMap;
