import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Stack,
} from '@mui/material';
import SpendingCategory from './spendingCategory';
import AnomalyDetection from './anomalyDetection';
import SuccessImg from '../../../assets/images/Success.png';
import { RootStyle, MainStyle } from '../../../components/Common/Style';
import IncomeVerificationUINav from '../../../components/Navbar/DashboardUINav/IncomeVerificationUINav';
import { useLocation } from 'react-router-dom';
import MyMap from './myMap';
import moment from 'moment';
import { getIncomeVerification } from '../../../helpers/backend_helper';
import { toast } from 'react-toastify';
import Loader from '../../../components/Common/Loader';
import { isNumber } from 'lodash';

const monthRange = [
  { title: '12 Months', value: 12 },
  { title: '9 Months', value: 9 },
  { title: '6 Months', value: 6 },
  { title: '3 Months', value: 3 },
];

function IncomeVerificationMap() {
  const location = useLocation();
  const [selectedMonth, setSelectedMonth] = useState(monthRange[0]?.value);
  const [months, setMonths] = useState([]);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // Theme and Responsive Hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    fetchTemplate();
  }, []);

  useEffect(() => {
    generateMonthOptions();
  }, [location?.state?.dateFrom, location?.state?.dateTo]);

  const generateMonthOptions = () => {
    let dateRangeArray = [
      {
        startDate: location.state?.dateFrom,
        endDate: location.state?.dateTo,
      },
    ];
    calculateDateRange(dateRangeArray, setMonths);
  };

  const fetchTemplate = async (newDate) => {
    setIsLoading(true);
    const selectedValue = newDate || selectedMonth;
    const date_to = isNumber(selectedValue)
      ? moment().subtract(selectedValue, 'M').format('YYYY-MM-DD')
      : selectedValue;
    const payload = {
      userId: location?.state?.userId,
      date_to,
      n_months: selectedValue <= 12 ? selectedValue : 1,
      account_id: location?.state?.accountNumber,
      show_reversal: true,
      accoutnType: 'Personal',
      testModel: true,
    };

    await getIncomeVerification(payload)
      .then((res) => {
        if (res.codes === 200) {
          setData(res?.data?.incomeVerificationDetails);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch(() => {
        toast.error('Something went wrong!');
      })
      .finally(() => setIsLoading(false));
  };

  const handleMonthChange = (e) => {
    const newDate = e.target.value;
    setSelectedMonth(newDate);
    fetchTemplate(newDate);
  };

  return (
    <RootStyle>
      <IncomeVerificationUINav location={location} />

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // minHeight: '100vh',
            width: '100%',
          }}
        >
          <Loader />
        </Box>
      ) : (
        <MainStyle>
          <Box sx={{ px: isMobile ? 2 : 7, py: 10 }}>
            <Grid container spacing={isMobile ? 2 : 4}>
              {/* Header & Month Selector */}
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection={isMobile ? 'column' : 'row'}
                >
                  <Typography
                    variant={isMobile ? 'h5' : 'h4'}
                    fontWeight="bold"
                    color="orangered"
                  >
                    <Stack
                      direction="column"
                      alignItems="start"
                      justifyContent="center"
                      ml={2}
                    >
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography
                          variant="h4"
                          fontWeight="bold"
                          color="orangered"
                        >
                          {location?.state?.clientName ||
                            location?.state?.firstName}
                        </Typography>
                        <Box component="img" src={SuccessImg} />
                      </Stack>
                    </Stack>
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={isMobile ? 2 : 0}
                    width={isMobile ? '100%' : 'auto'}
                  >
                    <Typography
                      variant="body2"
                      color="orangered"
                      sx={{ mr: 2 }}
                    >
                      Select Month
                    </Typography>
                    <Select
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      size="small"
                      fullWidth={isMobile}
                      sx={{
                        minWidth: 120,
                        border: '1px solid orangered',
                      }}
                    >
                      {months.map((month) => (
                        <MenuItem key={month.value} value={month.value}>
                          {month.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </Grid>

              {/* Map & Data Section */}
              <Grid item xs={12} md={8}>
                <Card sx={{ boxShadow: 2, borderRadius: 2 }}>
                  <CardContent>
                    <Typography variant="h6" fontWeight="bold">
                      Map Location of Spending
                    </Typography>
                    <Box
                      sx={{
                        height: isMobile ? '50vh' : '100vh',
                        borderRadius: '10px',
                        overflow: 'hidden',
                      }}
                    >
                      <MyMap data={data} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Right Panel for Categories & Anomaly Detection */}
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%', // Ensure the right panel matches the map
                    justifyContent: 'space-between',
                  }}
                >
                  <Card sx={{ boxShadow: 2, borderRadius: 2, flexGrow: 1 }}>
                    <CardContent>
                      <Typography variant="h6" fontWeight="bold">
                        Spending Category
                      </Typography>
                      <SpendingCategory data={data} />
                    </CardContent>
                  </Card>

                  <Card
                    sx={{ boxShadow: 2, borderRadius: 2, mt: 3, flexGrow: 1 }}
                  >
                    <CardContent>
                      <Typography variant="h6" fontWeight="bold">
                        Anomaly Detection
                      </Typography>
                      <AnomalyDetection
                        anomaliesCount={
                          data?.additional_analysis?.anomalies?.length
                        }
                      />
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </MainStyle>
      )}
    </RootStyle>
  );
}
function calculateDateRange(dateRange, setMonths) {
  let result = [];
  dateRange?.forEach((v) => {
    let startDate = moment(v.startDate);
    let endDate = moment(v.endDate);

    if (endDate.isBefore(startDate)) return;

    while (startDate.isBefore(endDate)) {
      let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
      if (moment(endDate).diff(moment(lastDate), 'days') < 0) {
        lastDate = moment(endDate).format('YYYY-MM-DD');
      }
      result.push(lastDate);
      startDate.add(1, 'month');
    }
  });

  const uniqueDates = [...new Set(result)].map((i) => ({
    title: i,
    value: i,
  }));

  setMonths([...monthRange, ...uniqueDates]);
}

export default IncomeVerificationMap;
