import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
// material-ui
import {
  Box,
  Card,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
// assets
import DeleteIcon from '@mui/icons-material/Delete';
// import getAxiosInstance from 'api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { inComeVerificationList } from '../../../helpers/backend_helper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// table header
const headCells = [
  {
    id: 'referenceId',
    numeric: false,
    disablePadding: false,
    label: 'Reference Id',
  },
  {
    id: 'firstName',
    numeric: true,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'lastName',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  border: '1px solid #DEDEDE',
  color: '#212120',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
}));

// ==============================|| TABLE - HEADER ||============================== //

function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#F0F0F0',
    color: 'rgba(33, 33, 32, 0.60)',
    border: '1px solid #DEDEDE',
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCellHead
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : undefined}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCellHead>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// ==============================|| TABLE - TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
  <Toolbar
    sx={{
      p: 0,
      pl: 1,
      pr: 1,
      ...(numSelected > 0 && {
        color: (theme) => theme.palette.secondary.main,
      }),
    }}
  >
    {numSelected > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {numSelected} selected
      </Typography>
    ) : (
      <Typography variant="h4" id="tableTitle">
        Nutrition
      </Typography>
    )}
    <Box sx={{ flexGrow: 1 }} />
    {numSelected > 0 && (
      <Tooltip title="Delete">
        <IconButton size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

// ==============================|| TABLE - ENHANCED ||============================== //

export default function Templates({ isOpen }) {
  const theme = useTheme();
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('userData'));
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [limit, setLimit] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiList, setApiList] = useState([]);
  const [total, setTotal] = useState();
  const [isFilter, setIsFilter] = useState(true);
  const [transactionsList, setTransactionsList] = useState([]);
  const [template, setTemplate] = useState([]);
  const openModel = Boolean(anchorEl);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedContentId, setSelectedContentId] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
  };

  const isSelected = () => {};

  const fetchTemplate = async () => {
    const payload = {
      page: page + 1,
      limit: limit,
      sortBy: orderBy,
      sortDirection: order,
      search: '',
    };
    await inComeVerificationList(payload)
      .then((res) => {
        setTemplate(res.data[0] || []);
        setTotal(res.data[1]);
      })
      .catch((err) => {
        toast.error('Something went wrong!');
      });
  };

  useEffect(() => {
    fetchTemplate();
  }, [limit, page, isOpen]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
  };

  const handleCopy = (row) => {
    navigator.clipboard.writeText(
      process.env.REACT_APP_EDOC_API_URL +
        '/income-verification/partner/' +
        selectedContentId
    );
    toast.success('Link copied to clipboard!');
  };
  const handleRedirect = (row) => {
    if (row?.status === 'Verified') {
      history.push('/income-verification-overview', { ...row });
    }
  };

  return (
    <Box mt={1}>
      <Card sx={{ mt: 2 }}>
        <>
          <TableContainer>
            <Table
              sx={{
                minWidth: 750,
                backgroundColor: 'white',
              }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={total}
              />

              <TableBody>
                {template.length ? (
                  <>
                    {template.map((row, index) => {
                      if (typeof row === 'number') return null;
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      const handleClick = (event) => {
                        setAnchorEl(event.currentTarget);
                      };

                      const handleClose = () => {
                        setAnchorEl(null);
                      };

                      const open = Boolean(anchorEl);

                      return (
                        <TableRow
                          key={row.name}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          <StyledTableCell
                            id={labelId}
                            onClick={() => handleRedirect(row)}
                            sx={{
                              cursor: row?.status === 'Verified' && 'pointer',
                              textDecoration: 'bold',
                            }}
                          >
                            {row.consentId}
                          </StyledTableCell>
                          <StyledTableCell>{row.firstName}</StyledTableCell>
                          <StyledTableCell>{row.lastName}</StyledTableCell>
                          <StyledTableCell>
                            <Chip
                              label={row.status}
                              size="small"
                              sx={{
                                color:
                                  row.status !== 'Verified'
                                    ? '#ACAC2A'
                                    : '#1CB10F',
                                backgroundColor:
                                  row.status !== 'Verified'
                                    ? '#FFFFE5'
                                    : '#E8F7E7',
                                fontSize: '14px',
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              width: '5px',
                              textAlign: 'center',
                            }}
                          >
                            <MoreVertIcon
                              sx={{ cursor: 'pointer' }}
                              onClick={(event) => {
                                setSelectedValue(row?.shareLink);
                                handleClick(event, row);
                                setSelectedContentId(row?.consentId);
                              }}
                            />
                          </StyledTableCell>
                          <Menu
                            id={`simple-menu-${index}`}
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            <MenuItem
                              id={row?.shareLink}
                              onClick={(e) => {
                                handleCopy(row);
                                handleClose();
                              }}
                            >
                              Copy Link
                            </MenuItem>
                          </Menu>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* table pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Card>
    </Box>
  );
}
