import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import Logo from '../../../assets/images/Logo.svg';
import MyAccount from "./MyAccount";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 95;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  minHeight: APPBAR_MOBILE,
  zIndex: 99,
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
  },
}));

const BoxWrap = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  display: "flex",
  width: "100%",
  position: "absolute",
  bottom: 0,
  justifyContent: "center",
  padding: theme.spacing(0, 5),
}));

const MainStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const HeaderItemsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1),
  marginRight: theme.spacing(5),

  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    gap: theme.spacing(4),
  },
}));

const UserNameText = styled(Typography)(({ theme }) => ({
  ...theme.typography.Body_semibold_20,
  color: theme.palette.common.black,
  [theme.breakpoints.up("sm")]: {
    ...theme.typography.Body_semibold_24,
  },
}));

// ----------------------------------------------------------------------

export default function IncomeVerificationUINav({ dashboardData, location, consent }) {
  const history = useHistory();
  const [openIncome, setOpenIncome] = useState(false);
  const [openExpenses, setOpenExpenses] = useState(false);
  const [openAffordability, setOpenAffordability] = useState(false);

  const handleCloseIncomeDialog = () => {
    setOpenIncome(false);
  };

  const handleCloseExpensesDialog = () => {
    setOpenExpenses(false);
  };

  const handleCloseAffordabilityDialog = () => {
    setOpenAffordability(false);
  };

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);

  const theme = useTheme();

  const isActiveRoot = useLocation();

  const ListItemStyle = styled((props) => <ListItemButton {...props} />)(
    ({ theme }) => ({
      ...theme.typography.Body_medium_14,
      position: 'relative',
      textAlign: 'center',
      textTransform: 'initial',
      padding: theme.spacing(2, 1),
      margin: theme.spacing(0.5, 1),
      color: theme.palette.common.black,
      borderBottom: `2px solid transparent`,
      maxHeight: 66,
      '&:hover': {
        backgroundColor: 'transparent', // Removes hover background effect
        color: theme.palette.orange.main,
      },
    })
  );
  const activeRootStyle = {
    color: theme.palette.orange.main,
    borderBottom: `2px solid ${theme.palette.orange.main}`,
    '&:before': { display: 'block' },
  };

  const handleIncomeVerificationOverviewRedirect = () => {
    history.push('/income-verification-overview', { ...location?.state });
  };

  const handleSpendingPatternRedirect = () => {
    history.push('/spending-pattern', { ...location?.state });
  };

  const displayDesktop = () => {
    return (
      <>
        <BoxWrap>
          <MainStyle>
            {userDetails}
            {headerItems}
          </MainStyle>
        </BoxWrap>
      </>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={2}
        >
          <Box component="img" src={Logo} alt="Logo" width={94} height={41} />

          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                display: { lg: 'none' },
              }}
            >
              <KeyboardArrowDownIcon
                sx={{ fill: theme.palette.orange.main }}
                fontSize="large"
              />
            </IconButton>

            <IconButton
              {...{
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                sx={{ fill: theme.palette.orange.main }}
                fontSize="large"
              />
            </IconButton>
          </Stack>
        </Stack>

        <Dialog
          {...{
            open: state.drawerOpen,
            onClose: handleDrawerClose,
          }}
          PaperProps={{
            sx: { width: '100%' },
          }}
        >
          <Stack direction="column" alignItems="center" gap={3}>
            <Box
              py={4}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: '1px solid #002E45',
              }}
            >
              <MyAccount />
            </Box>

            {headerItems}
          </Stack>
        </Dialog>

        {isActiveRoot.pathname === '/dashboard-overview' && (
          <Box m={4}>{userDetails}</Box>
        )}
      </>
    );
  };

  const userDetails = (
    <Stack direction="row" alignItems="center">
      <Box
        component={RouterLink}
        to="/income-verification"
        sx={{ color: 'black' }}
      >
        <ChevronLeftIcon sx={{ cursor: 'pointer' }} />
      </Box>

      {/* <Stack
        direction="column"
        alignItems="start"
        justifyContent="center"
        ml={2}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <UserNameText>
            {location?.state?.clientName || location?.state?.firstName}
          </UserNameText>
          <Box component="img" src={SuccessImg} />
        </Stack>
      </Stack> */}
    </Stack>
  );

  const headerItems = (
    <HeaderItemsWrapper>
      <ListItemStyle
        onClick={handleIncomeVerificationOverviewRedirect}
        sx={{
          ...(isActiveRoot.pathname === '/income-verification-overview' &&
            activeRootStyle),
        }}
      >
        Overview
      </ListItemStyle>
      <ListItemStyle
        onClick={handleSpendingPatternRedirect}
        sx={{
          ...(isActiveRoot.pathname === '/spending-pattern' && activeRootStyle),
        }}
      >
        Spending Pattern
      </ListItemStyle>
      <ListItemStyle
      // onClick={handleSpendingPatternRedirect}
      // sx={{
      //   ...(isActiveRoot.pathname === '/spending-pattern' &&
      //     activeRootStyle),
      // }}
      >
        Download Score
      </ListItemStyle>
    </HeaderItemsWrapper>
  );

  return (
    <>
      <RootStyle>
        <>{state.mobileView ? displayMobile() : displayDesktop()}</>
      </RootStyle>
    </>
  );
}