import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  Autocomplete,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  initiateInComeVerification,
  setTemplate,
} from '../../../helpers/backend_helper';
import { toast } from 'react-toastify';

const style = {
  maxWidth: '500px',
  width: '90%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  display: 'flex',
  flexDirection: 'column',
};

const PaymentLinkModal = ({ handleNewTemplate, isOpen }) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formattedTomorrow = tomorrow.toISOString().split('T')[0];
  const twelveMonthsFromNow = new Date(today);
  twelveMonthsFromNow.setFullYear(twelveMonthsFromNow.getFullYear() + 1);
  const formattedTwelveMonthsFromNow = twelveMonthsFromNow
    .toISOString()
    .split('T')[0];

  const [templateData, setTemplateData] = useState({
    firstName: '',
    firstNameError: null,
    lastName: '',
    lastNameError: null,
    email: '',
    emailError: null,
  });

  const [maxPaymentCount, setMaxPaymentCount] = useState(12);

  useEffect(() => {
    if (templateData.frequency === 'Weekly') {
      setMaxPaymentCount(52);
    } else if (templateData.frequency === 'Monthly') {
      setMaxPaymentCount(12);
    }
  }, [templateData.frequency]);

  const handleChange = (name, value) => {
    let newState = { ...templateData };
    if (value) {
      newState[name + 'Error'] = null;
    }
    if (name === 'paymentCount') {
      if (value <= maxPaymentCount) {
        newState[name] = value;
      }
    } else {
      newState[name] = value;
    }
    setTemplateData(newState);
  };

  const handleCreateTemplate = async () => {
    let newState = { ...templateData };
    if (newState.firstName === '') {
      newState.firstNameError = 'First Name is required.';
    }
    if (newState.lastName === '') {
      newState.lastNameError = 'Last Name is required.';
    }
    if (newState.email === '') {
      newState.emailError = 'Email Address is required.';
    }

    setTemplateData(newState);

    if (newState.firstName && newState.lastName && newState.email) {
      await initiateInComeVerification({
        firstName: newState.firstName,
        lastName: newState.lastName,
        email: newState.email,
      })
        .then((res) => {
          setTemplateData({
            firstName: '',
            firstNameError: null,
            lastName: '',
            lastNameError: null,
            email: '',
            emailError: null,
          });
          toast.success('income verification link initiate successfully!');
          handleNewTemplate();
        })
        .catch((err) => {
          toast.error('Something went wrong!');
        });
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleNewTemplate}>
      <DialogTitle>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h6" fontWeight={600}>
            Create Verification Link
          </Typography>
          <IconButton onClick={handleNewTemplate}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Box>
          <Grid
            container
            spacing={2}
            direction="column"
            sx={{ width: '400px' }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                First Name
              </Typography>
              <TextField
                type="text"
                fullWidth
                value={templateData.firstName}
                placeholder="Enter First Name"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('firstName', e.target.value)}
                helperText={templateData.firstNameError}
                error={Boolean(templateData.firstNameError)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                Last Name
              </Typography>
              <TextField
                type="text"
                fullWidth
                value={templateData.lastName}
                placeholder="Enter Last Name"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('lastName', e.target.value)}
                helperText={templateData.lastNameError}
                error={Boolean(templateData.lastNameError)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={500} mt={1}>
                Email Address
              </Typography>
              <TextField
                type="text"
                fullWidth
                value={templateData.email}
                placeholder="Enter Email Address"
                sx={{ mt: 1 }}
                onChange={(e) => handleChange('email', e.target.value)}
                helperText={templateData.emailError}
                error={Boolean(templateData.emailError)}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          sx={{ color: '#000', borderColor: '#DEDEDE' }}
          onClick={() => {
            handleNewTemplate();
            // setTemplateData();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ color: '#fff' }}
          onClick={handleCreateTemplate}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentLinkModal;
