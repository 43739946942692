import React from 'react';
import { Box, Typography } from '@mui/material';

const AnomalyDetection = ({ anomaliesCount }) => {
  return (
    <Box
      sx={{
        marginTop: '20px',
        padding: '15px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        height: '210px',
        maxWidth: '400px',
      }}
    >
      <Typography variant="body2" sx={{ marginTop: '20px' }}>
        Suspicious activities found during our analysis
      </Typography>
      <Typography sx={{ fontWeight: 'bold', marginTop: '30px' }}>
        <span style={{ color: '#ff5722' }}>Alert!</span> We found{' '}
        <strong>
          <span style={{ color: '#ff5722' }}>{anomaliesCount} anomalies</span>
        </strong>{' '}
        during our analysis
      </Typography>
    </Box>
  );
};

export default AnomalyDetection;
