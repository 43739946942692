import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_EDOC_API_URL || '';
class JwtService {
  init() {
    this.setInterceptors();
    // axios.defaults.headers.common['responseCode'] = 'starfolioapi';
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            // window.location = "/logout";
          }
          throw err;
        });
      }
    );
  };

  getWithParams = (url, params) => {
    // const token = 'token'
    // let header = {};
    // if(token) {
    // 	header.KEY = token;
    // }
    let authUser = localStorage.getItem('authUser');
    let headers = {};
    if (authUser) {
      let token = JSON.parse(localStorage.getItem('authUser')).data.accessToken;
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(`${BASE_API_URL}${url}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...headers },
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((err) => (window.location = '/logout'));
  };

  post = (url, data) => {
    let authUser = localStorage.getItem('authUser');
    let headers = {};

    if (authUser) {
      let token = JSON.parse(authUser).data.accessToken;
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(`${BASE_API_URL}${url}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('authUser'); // Clear auth token
          window.location.href = '/login'; // Redirect to login page
          return Promise.reject('Unauthorized - Redirecting to login');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((err) => {
        console.error('Error:', err);
      });
  };

  get = (url) => {
    let authUser = localStorage.getItem('authUser');
    let headers = {};

    if (authUser) {
      let token = JSON.parse(authUser).data.accessToken;
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(`${BASE_API_URL}${url}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...headers },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('authUser'); // Clear auth data
          window.location.href = '/login'; // Redirect to login page
          return Promise.reject('Unauthorized - Redirecting to login');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((err) => {
        console.error('Error:', err);
        window.location.href = '/login'; // Ensure redirection on error
      });
  };

  put = (url, data) => {
    const authUser = localStorage.getItem('authUser');
    const headers = {};

    if (authUser) {
      const token = JSON.parse(authUser).data.accessToken;
      headers['Authorization'] = `Bearer ${token}`;
    }

    return fetch(`${BASE_API_URL}${url}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 401) {
          // Clear stored authentication data
          localStorage.removeItem('authUser');
          // Redirect to the login page
          window.location.href = '/login';
          // Return a rejected promise to halt further processing
          return Promise.reject('Unauthorized - Redirecting to login');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((err) => {
        console.error('Error:', err);
        // Ensure redirection on error
        window.location.href = '/login';
      });
  };
}

const instance = new JwtService();

export default instance;
