import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Stack,
} from '@mui/material';
import SuccessImg from '../../assets/images/Success.png';

import { useLocation } from 'react-router-dom';
import Loader from '../../components/Common/Loader';
import { MainStyle, RootStyle } from '../../components/Common/Style';
import IncomeVerificationUINav from '../../components/Navbar/DashboardUINav/IncomeVerificationUINav';
import ApexSingleLineChart from './lineChart';
import { getIncomeVerification } from '../../helpers/backend_helper';
import { toast } from 'react-toastify';
import moment from 'moment';
import { isNumber } from 'lodash';
const monthRange = [
  {
    title: '12 Months',
    value: 12,
  },
  {
    title: '9 Months',
    value: 9,
  },
  {
    title: '6 Months',
    value: 6,
  },
  {
    title: '3 Months',
    value: 3,
  },
];

const IncomeVerificationDashboard = () => {
  const [selectedMonth, setSelectedMonth] = useState(monthRange[0]?.value);
  const [months, setMonths] = useState([]);
  const [employmentHistory, setEmploymentHistory] = useState([]);
  const [chartData, setChartData] = useState({ categories: [], values: [] });
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    fetchTemplate();
  }, []);
  useEffect(() => {
    generateMonthOptions();
  }, [location?.state?.dateFrom, location?.state?.dateTo]);

  const generateMonthOptions = () => {
    let dateRangArray = [
      {
        startDate: location.state?.dateFrom,
        endDate: location.state?.dateTo,
      },
    ];
    calculateDateRang(dateRangArray, setMonths);
  };

  const fetchTemplate = async (newDate) => {
    setIsLoading(true);
    const selectedValue = newDate || selectedMonth;
    const date_to = isNumber(selectedValue)
      ? moment().format('YYYY-MM-DD')
      : selectedValue;

    const payload = {
      userId: location?.state?.userId,
      date_to,
      n_months: selectedValue <= 12 ? selectedValue : 1,
      account_id: location?.state?.accountNumber,
      show_reversal: true,
      accoutnType: 'Personal',
      testModel: true,
    };

    await getIncomeVerification(payload)
      .then((res) => {
        if (res.codes === 200) {
          const data = res?.data?.incomeVerificationDetails || [];
          const employmentData =
            res?.data?.incomeVerificationDetails?.additional_analysis
              ?.employment_history || [];

          // Convert data to DATE-WISE categories
          const categories = data?.data.map(
            (item) => moment(item.date).format('DD-MMM-YYYY') // Change to Date-wise format
          );

          const values = data?.data.map((item) => item.amount);
          setChartData({ categories, values });
          setEmploymentHistory(employmentData);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch(() => {
        toast.error('Something went wrong!');
      })
      .finally(() => setIsLoading(false));
  };

  const handleMonthChange = (e) => {
    const newDate = e.target.value;
    setSelectedMonth(newDate);
    fetchTemplate(newDate);
  };

  return (
    <RootStyle>
      <IncomeVerificationUINav location={location} />

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
          }}
        >
          <Loader />
        </Box>
      ) : (
        <MainStyle>
          <Box sx={{ px: isMobile ? 2 : 7, py: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection={isMobile ? 'column' : 'row'}
                >
                  <Stack
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                    ml={2}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        color="orangered"
                      >
                        {location?.state?.clientName ||
                          location?.state?.firstName}
                      </Typography>
                      <Box component="img" src={SuccessImg} />
                    </Stack>
                  </Stack>
                  <Typography variant="h4" fontWeight="bold" color="orangered">
                    {/* Income Verification Dashboard */}
                  </Typography>
                  <Box display="flex" alignItems="center" mt={isMobile ? 2 : 0}>
                    <Typography
                      variant="body2"
                      color="orangered"
                      sx={{ mr: 2 }}
                    >
                      Select Month
                    </Typography>

                    <Select
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      size="small"
                      sx={{
                        minWidth: 120,
                        border: '1px solid orangered',
                      }}
                    >
                      {months.map((month) => (
                        <MenuItem key={month.value} value={month.value}>
                          {month.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </Grid>

              {/* Flex Container for Equal Height Cards */}
              <Grid item xs={12}>
                <Box
                  display="flex"
                  gap={3}
                  height={'70vh'}
                  flexDirection={isMobile ? 'column' : 'row'}
                >
                  {/* Employment History Card */}
                  <Card
                    variant="outlined"
                    sx={{
                      boxShadow: 2,
                      borderRadius: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: '30%',
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Employment History
                      </Typography>
                      <Typography variant="body2" paragraph>
                        Below is the list of individuals who consistently make
                        payments to this account.
                      </Typography>
                      <Box
                        sx={{
                          flexGrow: 1,
                          overflow: 'auto',
                          height: { height: 500 },
                        }}
                      >
                        <TableContainer component={Paper} elevation={0}>
                          <Table>
                            <TableHead>
                              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell>
                                  <strong>Company Name</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>Amount Credited</strong>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {employmentHistory.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{item.sender}</TableCell>
                                  <TableCell>
                                    ₦{' '}
                                    {item.amount.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                    })}{' '}
                                    {/* ₦ {item.amount} */}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </CardContent>
                  </Card>

                  {/* Salary Line Chart Card */}
                  <Card
                    sx={{
                      boxShadow: 2,
                      borderRadius: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                  >
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant="h6" fontWeight="bold">
                        Salary credited to this account
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}>
                        <ApexSingleLineChart data={chartData} />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </MainStyle>
      )}
    </RootStyle>
  );
};

function calculateDateRang(dateRage, setMonths) {
  var result = [];
  dateRage?.length &&
    dateRage?.forEach((v) => {
      var startDate = moment(v.startDate);
      var endDate = moment(v.endDate);
      if (endDate.isBefore(startDate)) {
        return;
      }
      while (startDate.isBefore(endDate)) {
        let lastDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
        let diff = moment(endDate).diff(moment(lastDate), 'days');
        if (diff < 0) {
          lastDate = moment(endDate).format('YYYY-MM-DD');
        }
        result.push(lastDate);
        startDate.add(1, 'month');
      }
      result
        .sort(
          (a, b) =>
            new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')
        )
        .reverse();
      function filterUniqueDates(data) {
        const lookup = new Set();
        return data.filter((date) => {
          const serialised = new Date(date).getTime();
          if (lookup.has(serialised)) {
            return false;
          } else {
            lookup.add(serialised);
            return true;
          }
        });
      }
      let uniqueDates = filterUniqueDates(result);
      uniqueDates =
        uniqueDates.length &&
        uniqueDates.map((i) => {
          return {
            title: i,
            value: i,
          };
        });
      setMonths([...monthRange, ...uniqueDates]);
    });
}

export default IncomeVerificationDashboard;
